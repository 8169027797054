import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/hoc/PrivateRoute";
import PageLoading from "./components/spinners/PageLoading";
import OutletWithProvider from "./context/OutletWithProvider";

const PageNotFound = React.lazy(() => import("./pages/auth/PageNotFound"));
const Wrapper = React.lazy(() => import("./pages/auth/Wrapper"));
const LoginPage = React.lazy(() => import("./pages/auth/Login"));
const SignupPage = React.lazy(() => import("./pages/auth/Signup"));
const CreateOrganization = React.lazy(
  () => import("./pages/auth/CreateOrganization")
);

const Dashboard = React.lazy(() => import("./pages/overview/Dashboard"));
const SubscriptionPlans = React.lazy(
  () => import("./pages/subscription/SubscriptionPlans")
);
const VerifyOtp = React.lazy(() => import("./pages/subscription/VerifyOtp"));
const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));
const ResetPasswordOtp = React.lazy(
  () => import("./pages/auth/ResetPasswordOtp")
);
/**Membership */
const RegisterMember = React.lazy(
  () => import("./pages/members/RegisterMember")
);
const BulkRegisterMember = React.lazy(
  () => import("./pages/members/BulkRegisterMember")
);
const Members = React.lazy(() => import("./pages/members/Members"));
const MemberDetails = React.lazy(() => import("./pages/members/MemberDetails"));
const SubscriptionHistory = React.lazy(
  () => import("./pages/subscription/SubscriptionHistory")
);

const AccountSettings = React.lazy(
  () => import("./pages/account-settings/AccountSettings")
);
const PaymentPage = React.lazy(
  () => import("./pages/payment-page/PaymentPage")
);
const GeneralPaymentPage = React.lazy(
  () => import("./pages/payment-page/GeneralPaymentPage")
);

const Payments = React.lazy(() => import("./pages/payments/Payments"));

const OneTimePayment = React.lazy(
  () => import("./pages/payments/OneTimePayment")
);
const DailyPayment = React.lazy(() => import("./pages/payments/DailyPayment"));
const Attendance = React.lazy(() => import("./pages/attendance/Attendance"));
const AttendanceReport = React.lazy(
  () => import("./pages/attendance/AttendanceReport")
);

const AccountSuspended = React.lazy(
  () => import("./pages/auth/AccountSuspended")
);

const ContactSupport = React.lazy(
  () => import("./pages/contact-support/ContactSupport")
);

const ReportsPage = React.lazy(() => import("./pages/reports/ReportsPage"));
const ActivateOnlinePayment = React.lazy(
  () => import("./pages/activate-online-payment/ActivateOnlinePayment")
);

const PayoutHistory = React.lazy(
  () => import("./pages/payout-history/PayoutHistory")
);

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <Router>
        <Routes>
          <Route path="/*" element={<PageNotFound />} />
          <Route path="/" element={<Wrapper />} />
          <Route
            path="/organization-suspended"
            element={<AccountSuspended />}
          />
          <Route path="/signin" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPasswordOtp />} />
          <Route
            path="/payment-page/:organizationId/:organizationName"
            element={<PaymentPage />}
          />
          <Route path="/kelemm-pay" element={<GeneralPaymentPage />} />
          <Route
            path="/verify-transaction-otp/:reference"
            element={<VerifyOtp />}
          />
          <Route path="/create-organization" element={<CreateOrganization />} />
          <Route element={<PrivateRoute />}>
            <Route element={<OutletWithProvider />}>
              <Route
                path="/subscription-plans"
                element={<SubscriptionPlans />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/members" element={<Members />} />
              <Route
                path="/members/:membershipId"
                element={<MemberDetails />}
              />
              <Route
                path="/members/:action/:status"
                element={<RegisterMember />}
              />
              <Route
                path="/members/:action/:bulk/:status"
                element={<BulkRegisterMember />}
              />
              <Route path="/payment-history" element={<Payments />} />
              <Route path="/payout-history" element={<PayoutHistory />} />
              <Route path="/attendance" element={<Attendance />} />
              <Route
                path="/attendance/:memberId"
                element={<AttendanceReport />}
              />
              <Route
                path="/payment-history/one-time-payment/:oneTimeId"
                element={<OneTimePayment />}
              />
              <Route
                path="/payment-history/periodic-payment/:dailyId/:paymentType"
                element={<DailyPayment />}
              />
              <Route path="/account-settings" element={<AccountSettings />} />
              <Route
                path="/activate-online-payments"
                element={<ActivateOnlinePayment />}
              />
              <Route
                path="/subscription-history"
                element={<SubscriptionHistory />}
              />
              <Route path="/contact-support" element={<ContactSupport />} />
              <Route path="/reports" element={<ReportsPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>

      <ToastContainer />
    </Suspense>
  );
}

export default App;
